import React from "react";
import styled from "styled-components";

const Card = styled.li`
  box-shadow: 0px 0px 13px #231f2026;
  background-color: white;
  padding: 7rem 2rem 2rem 2rem;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 12rem;
  margin-bottom: 5rem;
  margin-right: 2rem;
  position: relative;
  @media (max-width: 1301px) {
    width: 21%;
  }
  @media (max-width: 1080px) {
    width: 36%;
  }
  @media (max-width: 896px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    height: auto;
  }
`;

const CardText = styled.p`
  margin-top: -3rem;
  display: inline;
  font-size: 1em;
  font-weight: bold;
  word-spacing: 0.1rem;

  @media (max-width: 500px) {
    height: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ themecolor }) => themecolor};
  width: 100px;
  height: 100px;
  border-radius: 100%;
    transform: translateY(-50%);
    top: 0;
    position: absolute;
`;
const CardLogo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

const ProgramCard = ({ logo, info, themeColor }) => {
  return (
    <Card>
      <LogoContainer themecolor={themeColor}>
        <CardLogo src={logo} alt={"placeholder"} />
      </LogoContainer>
      <CardText>{info} </CardText>
    </Card>
  );
};

export default ProgramCard;
